<template>


<div class="row">
    <Overlay v-if="loading"/>
    <div class="d-flex flex-1" v-if="report">
        <div class="col-6 border-end" v-if="report"> 
            <div class="input-group input-group-sm mb-2" >
                <div class="input-group-text">{{$t('Max records')}}</div>
                <input type="number" class="form-control" id="maxRecordsInput" placeholder="1000" v-model="report.maxRecords"/>
            </div>
            <ActiveFilters :data-object="report"></ActiveFilters>
        </div>
        <div class="col-6 " v-if="report">
            
            </div>
            <button type="button" class="btn btn-primary d-none" @click = "report.openReport('view','pdf')">{{$t("Preview")}}</button>
        </div>
    </div>
    <div class="d-flex border-top" v-if="report">
        <div class="position-absolute bottom-0 start-0" >
            <b>{{$t('Filter')}}:</b> {{report.filterObject.prettyString}}
        </div>
       
        <div class="d-flex position-absolute bottom-0 end-0">
            <button type="button" class="btn btn-primary btn-sm me-2">{{$t("Send Report")}}</button>
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle btn-sm" :disabled="!report.filterObject.filterString" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {{$t('Download')}}
                    <div class="spinner-border spinner-border-sm me-1" role="status" v-if="report && report.loading">
                        <span class="sr-only"></span>
                    </div>

                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" @click="report.openReport('download','pdf')">{{$t('PDF')}}</a></li>
                    <li><a class="dropdown-item" @click="report.openReport('download','docx')">{{$t('Word')}}</a></li>
                    <li><a class="dropdown-item" @click="report.openReport('download','xlsx')">{{$t('Excel')}}</a></li>
                </ul>
            </div>
    </div>
</div>
       

</template>
<script setup lang="ts">
    import {ref} from 'vue';
    import {reportStore} from 'o365.modules.Reporting.DataObject.ts';
    import ActiveFilters from "o365.vue.components.ActiveFilters.vue";
    import { Overlay } from 'o365.vue.components.jsx';
    const props = defineProps({reportId:{
        required:true
    }});
    const report = ref(null);
    const loading = ref(true);
    
    reportStore.getReportByID(props.reportId).then(r=>{
        report.value = r;
        r.setInitialFilter();
        loading.value = false;
    });


</script>
<style>
    html{
        overflow-x:hidden;
    }    
</style>